<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
      <h4 class="color-light ml-2 page_title"><span class="ul_text">OF</span>FER LIST</h4> 
    </CRow>
    <CCardBody>
      <div v-if="loading==true">Loading . . .</div>
      <div v-else>
        <CRow>
        <CCol>
          <CRow class="col-md-12">
            <!-- <label>Filter :</label>
            <CInput class="col-md-8"
            @keyup="customFilter"
            v-model="filter"
            type="search"
            placeholder="Type to Search" /> -->
            <label>Total Rows : {{totalRows}}</label>
          </CRow>
        </CCol>
        <CCol>
      <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect class="pb-2"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div>
    </CCol>
    </CRow>
    <CDataTable
      :items="items"
      :fields="fields"
      :dark="false"
      :items-per-page="tableperpage"
      pagination
      sorter
      hover
      striped
      border
      small
      fixed
      light
      column-filter
    >
    <!-- column-filter -->
    <template #category="{item}">
      <td>
        <span v-if="item.category == '0'">Mainstream</span>
        <span v-if="item.category == '1'">Adult</span>
      </td>
    </template>
    <template #revenuetrack="{item}">
      <td>
        <span v-if="item.revenuetrack == '0'">Don't Track</span>
        <span v-if="item.revenuetrack == '1'">Fixed ( {{item.revenuevalue}}$ )</span>
        <span v-if="item.revenuetrack == '2'">Automatic (macros)</span>
      </td>
    </template>
    <template #status="{item}"
            ><td class="status_td">
              <span v-if="item.status == true">Active</span>
              <span v-if="item.status == false">Inactive</span>
              </td></template
          >
    <template #action="{item}">
        <td>
          <div class="row ml-1">
          <span @click="edit(item)">
          <CImg src="img/logo/edit.png" class="ml-1 img-fluid" title="Edit"
                             style="cursor: pointer;"
                             ></CImg>
          </span>
          <span @click="update(item,'hide')">
          <CImg src="img/logo/delelt.png" class="img-fluid ml-1" title="Delete"
          style="cursor: pointer;"
          ></CImg>
          </span>
          <span  @click=update(item)>
            <CImg v-if="item.status == true" src="img/logo/push.png" class="img-fluid ml-1" title="Inactive" :pressed.sync="item.status"
          style="cursor: pointer;"
          ></CImg>
            <CImg v-if="item.status == false" src="img/logo/play.png" class="img-fluid ml-1" title="Active" :pressed.sync="item.status"
              style="cursor: pointer;"
          ></CImg>
          </span>
          <span @click="CloneOffer(item)">
            <CImg src="img/logo/clone.png" class="img-fluid ml-1" title="Clone"
                style="cursor: pointer;"
            ></CImg>
          </span>
          <!-- <CButton variant="outline" color="success" :pressed.sync="item.status" @click=update(item)>{{item.status ? 'Active ' : 'Deactive'}}</CButton> -->
          
          
          </div>
        </td>
      </template>
      <!--start Custom Filter start -->
      <template #category-filter="{item}">&nbsp;</template>
      <template #revenuetrack-filter="{item}">&nbsp;</template>
      <template #status-filter="{item}">&nbsp;</template>
      <template #action-filter="{item}">&nbsp;</template>
      <!--end Custom Filter start -->
      </CDataTable>
      </div> 
       
    </CCardBody>
    </CCard>

    <CModal
        title="Edit Offer"
        :show.sync="offer_modal"
        :centered="true"
        scrollable
        color="dark"
        size="xl"
        
      >
      <offer-modal :edit_offerdata="edit_offerdata" :offer_modal="offer_modal" @offermodal="closeModal"></offer-modal>
      <template #footer>
        <CButton @click="closeModal()" color="danger" style="display: none;">Close</CButton>
      </template>
      </CModal>
  </div>
</template>

<script>
import OfferService from './../../services/offerControlService'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from "vue-multiselect";
import OfferModal from './NewOffer.vue'

const fields = [
        { key: "_id", label: "Offer ID", sortable: true },
        { key: "offername", label: "Offer Name", sortable: true } ,
        { key: "category", label: "Category", sortable: true },
        { key: "revenuetrack", label: "Revenue track" ,sortable: true },
        { key: "status", label: "Status",sortable: true },
        { key: "action", label: "Action" ,sortable: true }
      ]
export default {
  components:{Multiselect,OfferModal},
  props:{small: Boolean},
  name: 'ListOffer',
  data() {
        return {
          offer_modal:false,
          edit_offerdata:{},
          all_data:[],
          filter: '',
          totalRows:0,
          loading:true,
          tableperpage:20,
          perPage:[20,50,100],
          editoffer: false,
          items:[],
          useremail:'',
          fields,
          startDate: 1325376000000,
          endDate: 1330560000000,  
        }
        },
      mounted(){
        if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }else{
        this.useremail = window.localStorage.getItem("email");
        this.getallData();
        if(localStorage.getItem("offer_table") != null)
        this.tableperpage = parseInt(localStorage.getItem("offer_table"));
        }
      },
      watch:{
        offer_modal(value){
          if(value == false){
          this.getallData();
          }
        }
      },
    computed: {
    computedItems () {
      return this.items.map(item => {
        return { 
          ...item, 
          // registeredTimestamp: item.registered.timestamp, 
          // registeredDate: item.registered.date 
        }
      })
    },
    dateFilteredItems() {
      return this.computedItems.filter(item => {
        // const date = item.registeredTimestamp
        // return date >= this.startDate && date <= this.endDate
      })
    }
  },
  methods: {
    closeModal(){
      this.offer_modal = false
      this.getallData();
    },
    customFilter() {
      let search = this.filter.toString().toLowerCase()
      if(search != ''){
      let f_data = this.all_data.filter(row => row.offername.match(search))
      if(f_data){return this.items = f_data}
      }
      else{return this.items = this.all_data}
    },
    checkPage(){
      localStorage.setItem("offer_table", this.tableperpage);
    },
    async getallData() {
      try {
        let response = await OfferService.get()
        //   this.users = response.data.message;
          this.items = response.message;
          this.all_data = response.message;
          this.totalRows = this.items.length;
          this.loading = false;
      } catch (e) {
        console.log('connection error')
        // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok'})
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    async update(formData, txt) {
      try {
        if (txt == "hide") {
          if (confirm("Are you sure you want to Delete?")) {
          let response = await OfferService.remove({ offerid: formData._id })
          if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
          }
          else{
            Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
          }
          }
        } else {
            var status = true
            if(formData.status == true){
              status = false
            }
            else{
              status == true
            }
            formData.status = status
          let updated_data = {
            offerid: formData._id,
            changePayload: formData
          };
           let response = await OfferService.modify(updated_data)

          if(response.result){
            let msg = formData.status ? "Successfully Offer Activated" : "Successfully Offer Deactivated";
            Swal.fire({title: 'Success!',text: msg,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 2000})
          }
          else{
            Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 2000})
          }
        }
        this.getallData();
      } catch (e) {
        // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok'})
        // this.errorNotify("Error", "connection error", "danger");
        if (formData.isactive == false) {
          formData.isactive = true;
        } else {
          formData.isactive = false;
        }
      }
    },
    async edit(data) {
      this.edit_offerdata = {}
      this.offer_modal = false;
      this.offer_modal = true;
      this.edit_offerdata = data;
      // this.$root.$data.offer = data;
      // this.$root.$data.edit = true;
      this.editoffer = true;
      // this.$router.push('/offer/newoffer');
    },
    async CloneOffer(formData){
      if(confirm("Are you sure you want to clone `"+ formData.offername +"` offer?")){
          let response = await OfferService.cloneOffer({ offerid: formData._id })
          if(response.result){
            Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 2000})
          }
          else{
            Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 2000})
          }
          this.getallData();
      }
    }
  }

}
</script>

<style scoped>
.status_td{
   width: 3% !important;
}
/* .table  thead  th {
  color: red;
  position: sticky !important;
  top: 0 !important;
} */
</style>
